import React, { PureComponent, Component } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import StoreContext from "@context/store.js";
import CartIcon from "@images/cart-icon.png";
import Close from "@images/ui-close.png";
import styles from "./index.module.scss";

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      fixed: false,
      display: "cart",
    };
    // this.timer = null;
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  componentDidMount() {
    this.handleSpacer();
    this.handleScroll();
    // this.timer = setInterval(() => {
    //   const { display } = this.state;

    //   this.setState({ display: display == "cart" ? "count" : "cart" });
    // }, 3000);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
    window.addEventListener("resize", this.handleSpacer);
  }
  componentWillUnmount() {
    // window.clearInterval(this.timer);
  }
  toggleMenu() {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  }
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  handleSpacer() {
    if (!this.headerRef || !this.headerInnerRef || !window) {
      return;
    }
    const height = this.headerInnerRef.offsetHeight;
    this.headerRef.style.minHeight = `${height}px`;
  }
  handleScroll() {
    if (!this.headerRef || !window) {
      return;
    }
    const { fixed } = this.state;
    const { isInterior } = this.props;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const headerDistanceFromTop = this.headerRef.offsetTop;
    if (scrollTop > headerDistanceFromTop && !isInterior) {
      this.headerInnerRef.classList.add(styles.headerInnerFixed);
      this.setState({ fixed: true });
    } else {
      this.headerInnerRef.classList.remove(styles.headerInnerFixed);
      this.setState({ fixed: false });
    }
  }
  render() {
    const { fixed, display } = this.state;
    const { isInterior } = this.props;
    return (
      <StoreContext.Consumer>
        {(store) => {
          // const count = store.checkout.lineItems;
          const {
            cartOpen,
            checkout: { lineItems },
          } = store;
          return (
            <header
              className={`${styles.header}`}
              ref={(ele) => {
                this.headerRef = ele;
              }}
            >
              <div
                className={`${styles.headerInner} ${
                  fixed ? styles.headerInnerFixed : ""
                } ${cartOpen && fixed ? styles.headerInnerFixedOpen : ""}`}
                ref={(ele) => {
                  this.headerInnerRef = ele;
                }}
              >
                <h1>
                  <Link to="/">Jujubee</Link>
                </h1>
                <ul className={styles.headerList}>
                  {/* <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/" : ""}#music`}>Music</Link>
                  </li>
                  <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/" : ""}#video`}>Video</Link>
                  </li> */}
                  <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/shop" : "#shop"}`}>Shop</Link>
                  </li>
                  <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/" : ""}#about`}>About</Link>
                  </li>
                  {/* <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/" : ""}#photos`}>Photos</Link>
                  </li> */}
                  <li className={styles.headerListItem}>
                    <Link to={`${isInterior ? "/" : ""}#contact`}>Contact</Link>
                  </li>
                  <li className={styles.headerListCart}>
                    <button
                      className={styles.headerCartToggle}
                      onClick={store.toggleCartOpen}
                    >
                      {cartOpen ? (
                        <img src={Close} />
                      ) : (
                        <>
                          {display == "cart" || lineItems.length == 0 ? (
                            <img src="/images/cart-icon.png" />
                          ) : (
                            <span>{lineItems.length}</span>
                          )}
                        </>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </header>
          );
        }}
      </StoreContext.Consumer>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
