import React, { useContext } from "react";
import StoreContext from "@context/store";
import TrashIcon from "@svg/ui-trash.inline.svg";
import { Link } from "gatsby";

import "./index.scss";

const Cart = () => {
  const context = useContext(StoreContext);
  const { checkout } = context;

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map((lineItem) => {
    return (
      <div className="product">
        <Link to={`/shop/${lineItem.variant.product.handle}`}>
          <div className="product-image">
            <img
              src={lineItem.variant.image.src}
              alt={`${lineItem.title} product shot`}
            />
          </div>
          <div className="product-details">
            <div className="product-title">
              {`${lineItem.title} `}
              {lineItem.variant.title !== "Default Title" ? (
                <span>{lineItem.variant.title}</span>
              ) : (
                ""
              )}
            </div>
            <p className="product-description">{lineItem.description}</p>
          </div>
          <div className="product-price">{lineItem.variant.price}</div>
        </Link>
        <div className="product-quantity">
          <input
            type="number"
            value={lineItem.quantity}
            disabled={true}
            min="1"
            max="9"
          />
        </div>

        <div className="product-removal">
          <button
            onClick={() => {
              context.removeLineItem(
                context.client,
                context.checkout.id,
                lineItem.id
              );
            }}
            className="remove-product"
          >
            <TrashIcon />
          </button>
        </div>
        <Link to={`/shop/${lineItem.variant.product.handle}`}>
          <div className="product-line-price">
            {lineItem.variant.price * lineItem.quantity}
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div className="shopping-cart">
      <div className="column-labels">
        <label className="product-image">Image</label>
        <label className="product-details">Product</label>
        <label className="product-price">Price</label>
        <label className="product-quantity">Qty</label>
        <label className="product-removal">Remove</label>
        <label className="product-line-price">Total</label>
      </div>

      {lineItems}
      {lineItems.length === 0 && (
        <div className="product product--cart-empty">
          <h3>Your cart is empty</h3>
        </div>
      )}

      <div className="totals">
        <div className="totals-item">
          <label>Subtotal</label>
          <div className="totals-value" id="cart-subtotal">
            {context.checkout.subtotalPrice
              ? `${context.checkout.subtotalPrice}`
              : "0.00"}
          </div>
        </div>
      </div>

      <button onClick={handleCheckout} className="checkout">
        Checkout
      </button>
      {/* <Link className="checkout" to="/shop">
        Continue Shopping
      </Link> */}
    </div>
  );
};

export default Cart;
